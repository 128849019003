import React from 'react'
import Img from 'gatsby-image'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { useStaticQuery, graphql } from 'gatsby'
import cateringMenu from '../../assets/menus/Catering & Private Events Menus.pdf'
import { Link } from 'gatsby'
import SummerDinnerPDF from '../../assets/menus/dinner-summer-2020.pdf'
import SummerLunchPDF from '../../assets/menus/lunch-summer-2020.pdf'
import {
  buttonStyles,
  buttonHoverStyles,
  plantinBoldItalic,
  darkGreen,
} from '../../styles'
import ToGoMenu from '../../assets/menus/togo-menu-new.pdf'
import FreshIngredientsMenu from '../../assets/menus/fresh-ingredients.pdf'
const Wrapper = styled.section`
  margin: 0 auto;
  position: relative;

  /* grid-template-columns: repeat(auto-fit, minmax(320px, 1fr)); */
`

import ThanksgivingHornImage from '../../assets/images/svg/thanksgiving-horn.svg'
const renderLink = ({ outbound, path, linkText }) => {
  if (!outbound) {
    return (
      <Link key={path} css={BlockLink} to={path}>
        {linkText}
      </Link>
    )
  } else if (path.includes('tel:')) {
    return (
      <a key={path} href={path} css={BlockLink}>
        {linkText}
      </a>
    )
  } else {
    return (
      <a
        key={path}
        target="_blank"
        rel="noopener noreferrer"
        href={path}
        css={BlockLink}
      >
        {linkText}
      </a>
    )
  }
}

const renderLinks = links => {
  if (!links.length) return
  return (
    <Links>
      {links.map(link => (
        <div key={link.path}>{renderLink(link)}</div>
      ))}
    </Links>
  )
}

const Links = styled.div`
  display: flex;
  flex-direction: column;
  align-items: space-around;
  @media screen and (min-width: 1420px) {
    justify-content: space-around;
    flex-direction: column;
    width: 100%;
  }
  > *:not(:last-of-type) {
    margin-bottom: 2rem;
  }
`

const Copy = styled.div`
  padding: 5rem 2rem;
  @media screen and (min-width: 920px) {
    padding: 5rem;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`
const CopyTitle = styled.h2`
  font-family: ${plantinBoldItalic};
  border-bottom: 2px solid ${darkGreen};
`
const CopyText = styled.p``
const ImageWrapper = styled.div`
  &.is-image {
    padding: 5rem;
  }
`

const BlockLink = css`
  ${buttonStyles}
  ${buttonHoverStyles}
`

const HeroImageQuery = () =>
  useStaticQuery(graphql`
    query IndexPageQuery {
      scallopPhoto: file(relativePath: { regex: "/scallop-hero.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      diningRoomPhoto: file(relativePath: { regex: "/dining-room.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 660) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      salmonPhoto: file(relativePath: { regex: "/salmon.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 660) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      oysterPhoto: file(relativePath: { regex: "/oyster.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 660) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      pastaPhoto: file(relativePath: { regex: "/pasta.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 660) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      lambChopsPhoto: file(relativePath: { regex: "/lamb-chops.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 660) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      barrelsPhoto: file(relativePath: { regex: "/barrels.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 660) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      diningRoom: file(relativePath: { regex: "/dining-room.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 660) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      guitarPoster: file(relativePath: { regex: "/classic-guitar-post.png/" }) {
        childImageSharp {
          fluid(maxWidth: 660) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      thanksgivingHorn: file(
        relativePath: { regex: "/svg/thanksgiving-horn.svg/" }
      ) {
        publicURL
      }
    }
  `)

const Row = styled.div`
  @media screen and (min-width: 1020px) {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    &:nth-last-of-type(even) {
      flex-direction: row-reverse;
    }
    > * {
      flex: 0 0 50%;
    }
  }
`

const Hero = () => {
  const {
    pastaPhoto,
    salmonPhoto,
    lambChopsPhoto,
    scallopPhoto,
    oysterPhoto,
    guitarPoster,
    diningRoom,
    thanksgivingHorn,
  } = HeroImageQuery()
  const blockItems = [
    // {
    //   relativePath: 0,
    //   title: 'Our Menus',
    //   copy: [
    //     'Welcome back! We missed you! We are now open inside and on the patio according to state guidelines. See you soon, and remember your mask!'
    //   ],
    //   image: burgerPhoto.childImageSharp.fluid,
    //   links: [
    //     {
    //       path: SummerDinnerPDF,
    //       linkText: 'Dinner Menu',
    //       outbound: true,
    //     },
    //     {
    //       path: SummerLunchPDF,
    //       linkText: 'Lunch Menu',
    //       outbound: true,
    //     }
    //   ],
    // },
    // {
    //   relativePath: 0,
    //   title: 'Thanksgiving Dinner Orders Now Available',
    //   copy: [
    //     <span key={21212}>
    //       Place your order for Your 2023 Thanksgiving Dinner by Magnolias order today.
    //     </span>,
    //   ],
    //   image: thanksgivingHorn.publicURL,
    //   isImg: true,
    //   links: [
    //     {
    //       path: '/thanksgiving-order',
    //       linkText: 'Order Now',
    //       outbound: false,
    //     },
    //   ],
    // },
    // {
    //   relativePath: 1,
    //   title: 'Magnolia’s Family Meals',
    //   copy: [
    //     <span key={21212}>Our popular Family Meals are now available for <strong><br/>Curbside Pickup</strong> and <strong>To-Go</strong>.</span>
    //   ],
    //   image: salmonPhoto.childImageSharp.fluid,
    //   links: [
    //     {
    //       path: 'https://www.magnoliascurbside.com/',
    //       linkText: 'Order Now',
    //       outbound: true,
    //     }
    //   ],
    // },
    {
      relativePath: 1,
      title: 'Catering',
      copy: [
        'Let us bring a ',
        <strong key={100}>Magnolias</strong>,
        ' experience to you.',
        <br key={101} />,
        'We would love to make your next gathering an event to remember with our full–service catering.',
      ],
      image: lambChopsPhoto.childImageSharp.fluid,
      links: [
        {
          path: cateringMenu,
          linkText: 'View Our Catering Menu',
          outbound: true,
        },
        {
          path: '/private-events/',
          linkText: 'Contact Our Events Team',
          outbound: false,
        },
      ],
    },

    //     {
    //       relativePath: 2,
    //       title: 'Our Menus',
    //       copy: [
    //         'We’re proud to make seasonal food from local farmers an essential part of ',
    //         <strong key={103}>Magnolias’s</strong>,
    //         ' hospitality. We offer daily specials in addition to our seasonally rotating dinner and lunch menus.',
    //       ],
    //       image: oysterPhoto.childImageSharp.fluid,
    //       links: [],
    //     },
    {
      relativePath: 3,
      title: 'Magnolias‘ Events',
      copy:
        'We regularly host special events highlighting the best in creative food & drink. Check out our up upcoming events and reserve your seat directly online.',
      image: scallopPhoto.childImageSharp.fluid,
      links: [
        {
          path: '/special-events',
          linkText: 'View Our Upcoming Events',
        },
      ],
    },
  ]
  return (
    <Wrapper>
      {blockItems.map(({ image, title, copy, links, isImg }, index) => (
        // <Block {...item} key={item.title} even={index % 2 === 0} />
        <Row key={title}>
          <ImageWrapper className={isImg ? 'is-image' : ''}>
            {isImg ? <img src={image} /> : <Img fluid={image}></Img>}
          </ImageWrapper>

          <Copy>
            <CopyTitle>{title}</CopyTitle>
            <CopyText>{copy}</CopyText>
            {!!links.length && <Links>{renderLinks(links)}</Links>}
          </Copy>
        </Row>
      ))}
    </Wrapper>
  )
}

export default Hero
